import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  { path: "*", component: () => import("@/views/others/404.vue") },
  {
    path: "/login",
    component: () => import("@/views/auth/_LayoutLogin.vue"),
    meta: { isPublic: true },
    redirect: { name: "login" },
    children: [
      {
        path: "/login",
        name: "login",
        meta: {
          title: "Inicio de sesión",
          descriptionLayout: "Inicio de sesión",
        },
        component: () => import("@/views/auth/Login.vue"),
      },
      {
        path: "/unauthorized",
        name: "unauthorized",
        meta: {
          title: "Okan sin autorización",
          descriptionLayout: "Okan sin autorización",
        },
        component: () => import("@/views/others/Unauthorized.vue"),
      },
    ],
  },
  {
    path: "/saludobienvenida",
    component: () => import("@/views/newUser/Layout.vue"),
    meta: { isPublic: true },
    redirect: { name: "newuser" },
    children: [
      {
        path: "/saludobienvenida",
        name: "Newuser",
        meta: {
          title: "Nuevo usuario"
        },
        component: () => import("@/views/newUser/newUser.vue"),
      },
      {
        path: "pasosiniciales",  
        name: "PasosIniciales", 
        meta: {
          title: "Pasos Iniciales"  
        },
        component: () => import("@/views/newUser/firststeps.vue"),
      },
    ],
  }
  ,
  {
    path: "/",
    component: () => import("@/views/auth/_Layout.vue"),
    redirect: { name: "home" },
    children: [
      {
        path: "ayuda",
        name: "helpHello",
        meta: {
          title: "Ayuda",
          descriptionLayout: "Ayuda",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: true,
              href: "home",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/components/Help.vue"),
      },
      {
        path: "home",
        name: "home",
        meta: {
          title: "Inicio Home",
          descriptionLayout: "Noticas y home",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: true,
              href: "home",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/home/IndexHome.vue"),
      },
      {
        path: "podcast",
        name: "podcast",
        meta: {
          title: "Podcast",
          descriptionLayout: "Podcast",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Podcast",
              disabled: true,
              href: "podcast",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/home/Podcast.vue"),
      },
      {
        path: "news",
        name: "listNews",
        meta: {
          title: "Actualidad BRM",
          descriptionLayout: "Noticas y home",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Actualidad BRM",
              disabled: true,
              href: "listNews",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/home/IndexNews.vue"),
      },
      {
        path: "news/:id",
        name: "newsDetails",
        meta: {
          title: "Actualidad BRM",
          descriptionLayout: "Noticas y home",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Actualidad BRM",
              disabled: false,
              href: "listNews",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Detalles",
              disabled: true,
              href: "newsDetails",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/home/NewsDetails.vue"),
      },
      {
        path: "app",
        name: "apps",
        meta: {
          title: "Aplicativos",
          descriptionLayout: "Aplicaciones",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Aplicativos",
              disabled: true,
              href: "apps",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/application/Apps.vue"),
      },
      {
        path: "app/details/:id",
        name: "appDetails",
        meta: {
          title: "Aplicativos",
          descriptionLayout: "Aplicaciones",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Aplicativos",
              disabled: false,
              href: "apps",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Detaller",
              disabled: true,
              href: "appDetails",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/application/Details.vue"),
      },
      {
        path: "app/reporting/:id",
        name: "appReporting",
        meta: {
          title: "Aplicativos",
          descriptionLayout: "Aplicaciones",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Aplicativos",
              disabled: false,
              href: "apps",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Detalles",
              disabled: true,
              href: "appDetails",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/application/Reporting.vue"),
      },
      {
        path: "profile",
        name: "profile",
        meta: {
          title: "Mi perfil",
          descriptionLayout: "Perfil usuario",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Mi perfil empresarial",
              disabled: true,
              href: "profile",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/user/Profile.vue"),
      },
      {
        path: "horas/extras",
        name: "extraHours",
        meta: {
          title: "Horas extras",
          descriptionLayout: "Horas extras",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Horas extras",
              disabled: true,
              href: "extraHours",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/extraHours/Index.vue"),
      },
      {
        path: "voluntary",
        name: "voluntaryRetirement",
        meta: {
          title: "Retiro voluntario",
          descriptionLayout: "Retiro voluntario",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Mi perfil empresarial",
              disabled: false,
              href: "profile",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Renuncia voluntaria",
              disabled: true,
              href: "voluntaryRetirement",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/resign/VoluntaryRetirement.vue"),
      },
      {
        path: "solicitudes",
        name: "actionToday",
        meta: {
          title: "Solicitudes",
          descriptionLayout: "Solicitudes",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Solicitudes",
              disabled: true,
              href: "actionToday",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/user/ActionToday.vue"),
      },
      {
        path: "solicitudes/voluntary",
        name: "reportRetirement",
        meta: {
          title: "Reporte de renuncia voluntaria",
          descriptionLayout: "Reporte de renuncia voluntaria",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Solicitudes",
              disabled: false,
              href: "actionToday",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Reporte de solicitudes",
              disabled: true,
              href: "reportRetirement",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/resign/ReportRetirement.vue"),
      },
      {
        path: "programas/brm",
        name: "programsBrm",
        meta: {
          title: "Programas BRM",
          descriptionLayout: "Programas BRM",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Programas BRM",
              disabled: true,
              href: "programsBrm",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/lifeControl/Index.vue"),
      },
      {
        path: "la/ficha",
        name: "referenceCard",
        meta: {
          title: "Le tengo la ficha",
          descriptionLayout: "Le tengo la ficha",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Le tengo la ficha",
              disabled: true,
              href: "programsBrm",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/lifeControl/ReferencesCard.vue"),
      },
      {
        path: "control/life",
        name: "lifeControl",
        meta: {
          title: "Control de vida",
          descriptionLayout: "Control de vida",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Control de vida",
              disabled: true,
              href: "lifeControl",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/lifeControl/IndexControl.vue"),
      },
      {
        path: "control/life/:id",
        name: "projectLife",
        meta: {
          title: "Control de vida",
          descriptionLayout: "Control de vida",
          // este es exclusivo de mobile
        },
        component: () => import("@/views/lifeControl/ProjectDetail.vue"),
      },
      {
        path: "labor/relations",
        name: "laborRelations",
        meta: {
          title: "Relaciones laborales",
          descriptionLayout: "Relaciones laborales",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Relaciones laborales",
              disabled: true,
              href: "laborRelations",
              active: "router-link-active",
            },
          ],
        },
        component: () =>
          import("@/views/laborRelationsModule/Index.vue"),
      },
      {
        path: "labor/relations/documents",
        name: "documentTracking",
        meta: {
          title: "Relaciones laborales documentos",
          descriptionLayout: "Relaciones laborales documentos",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Relaciones laborales",
              disabled: true,
              href: "laborRelations",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Documentos",
              disabled: true,
              href: "documentTracking",
              active: "router-link-active",
            },
          ],
        },
        component: () =>
          import("@/views/laborRelationsModule/DocumentTracking.vue"),
      },
      {
        path: "reference",
        name: "referenceReport",
        meta: {
          title: "Reporte de referidos",
          descriptionLayout: "Reporte de referidos",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Referidos",
              disabled: true,
              href: "referenceReport",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/reference/Index.vue"),
      },
       {
        path: "la/ficha/detalle/:id",
        name: "referenceReportDetail",
        meta: {
          title: "Reporte de referidos",
          descriptionLayout: "Reporte de referidos",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Referidos",
              disabled: true,
              href: "referenceReport",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Referidos Detalle",
              disabled: true,
              href: "referenceReportDetail",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/reference/ReferenceDetails.vue"),
      },
      {
        path: "linguo",
        name: "linguo",
        meta: {
          title: "Linguo",
          descriptionLayout: "Linguo",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Linguo",
              disabled: true,
              href: "linguo",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/application/Linguo.vue"),
      },
      {
        path: "linguo/dev",
        name: "linguoDev",
        meta: {
          title: "Linguo para desarrolladores",
          descriptionLayout: "Linguo para desarrolladores",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Linguo dev",
              disabled: true,
              href: "linguo dev",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/application/LinguoDev.vue"),
      },
      {
        path: "linguo/marcas/:id",
        name: "linguoBrands",
        meta: {
          title: "Linguo para marcas",
          descriptionLayout: "Linguo para marcas",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Linguo marcas",
              disabled: true,
              href: "linguoBrands",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/application/LinguoBrands.vue"),
      },
      {
        path: "seguridad",
        name: "security",
        meta: {
          title: "Módulo de seguridad",
          descriptionLayout: "Módulo de seguridad",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Seguridad de la Informacíon",
              disabled: true,
              href: "security",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/security/Index.vue"),
      },
      {
        path: "seguridad/ausencias",
        name: "securityAbsences",
        meta: {
          title: "Módulo de seguridad",
          descriptionLayout: "Módulo de seguridad",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Lista de ausencias",
              disabled: true,
              href: "security",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/security/AbsenceList.vue"),
      },
      {
        path: "seguridad/manager/add",
        name: "recordManager",
        meta: {
          title: "Documentos corporativos para gerentes",
          descriptionLayout: "Documentos corporativos para gerentes",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Seguridad de la Informacíon",
              disabled: true,
              href: "security",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Acta Entrega",
              disabled: true,
              href: "recordManager",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/security/RecordsManager.vue"),
      },
      {
        path: "seguridad/report/bonus",
        name: "RecordReport",
        meta: {
          title: "Reporte de Actas",
          descriptionLayout: "Reporte de Actas",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Seguridad de la Informacíon",
              disabled: true,
              href: "security",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Reporte de Actas",
              disabled: true,
              href: "RecordReport",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/security/RecordDocument.vue"),
      },
      {
        path: "seguridad/report/delete",
        name: "RecordDelete",
        meta: {
          title: "Reporte de Actas eliminadas",
          descriptionLayout: "Reporte de Actas eliminadas",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Seguridad de la Informacíon",
              disabled: true,
              href: "security",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Reporte de actas eliminados",
              disabled: true,
              href: "RecordDelete",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/security/RecordsDeleted.vue"),
      },
      {
        path: "forms",
        name: "forms",
        meta: {
          title: "BRM Pregunta",
          descriptionLayout: "BRM Pregunta",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "BRM Pregunta",
              disabled: true,
              href: "forms",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/forms/Index.vue"),
      },
      {
        path: "forms/details/:id",
        name: "formsDetails",
        meta: {
          title: "Formularios detalles",
          descriptionLayout: "Formularios detalles",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "BRM Pregunta",
              disabled: false,
              href: "forms",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Formulario",
              disabled: true,
              href: "formsDetails",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/forms/Details.vue"),
      },
      {
        path: "forms/details/answers/:id",
        name: "formsAnswers",
        meta: {
          title: "Respuestas formularios",
          descriptionLayout: "Respuestas formularios",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "BRM Pregunta",
              disabled: false,
              href: "forms",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Respuesta",
              disabled: true,
              href: "formsAnswers",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/forms/FormsAnswers.vue"),
      },
      {
        path: "forms/safe/passage/:result",
        name: "formsSagePassage",
        meta: {
          title: "Salvo conducto",
          descriptionLayout: "Salvo conducto",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "BRM Pregunta",
              disabled: false,
              href: "forms",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Detalles",
              disabled: true,
              href: "formsSagePassage",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/forms/SafePassage.vue"),
      },
      {
        path: "ReglamentoyPoliticas",
        name: "tyc",
        meta: {
          title: "Reglamentos y Políticas",
          descriptionLayout: "Reglamentos y Políticas",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Reglamentos y Políticas",
              disabled: true,
              href: "tyc",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/terms/Index.vue"),
      },
      {
        path: "ReglamentoyPoliticas/detalle",
        name: "tycDetail",
        meta: {
          title: "Términos y condiciones",
          descriptionLayout: "Términos y condiciones",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Reglamentos y Políticas",
              disabled: true,
              href: "tyc",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Detalle",
              disabled: true,
              href: "tycDetail",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/terms/Details.vue"),
      },
      {
        path: "document",
        name: "documents",
        meta: {
          title: "Documentos corporativos",
          descriptionLayout: "Documentos corporativos",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Documentos corporativos",
              disabled: true,
              href: "documents",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/document/Index.vue"),
      },
      {
        path: "document/details/:id/:docUser",
        name: "documentDetails",
        meta: {
          title: "Documentos corporativos detalles",
          descriptionLayout: "Documentos corporativos detalles",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Documentos",
              disabled: false,
              href: "documents",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Firma",
              disabled: true,
              href: "documentDetails",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/document/Details.vue"),
      },
      {
        path: "document/manager/add",
        name: "documentManager",
        meta: {
          title: "Documentos corporativos para gerentes",
          descriptionLayout: "Documentos corporativos para gerentes",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Reporte de nómina",
              disabled: false,
              href: "marginCommisions",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Bono no salarial",
              disabled: true,
              href: "documentManager",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/document/DocumentManager.vue"),
      },
      {
        path: "document/report/bonus",
        name: "documentReportBonus",
        meta: {
          title: "Reporte de documentos de bono no salarial",
          descriptionLayout: "Reporte de documentos de bono no salarial",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Reporte de nómina",
              disabled: false,
              href: "marginCommisions",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Reporte de bono no salarial",
              disabled: true,
              href: "documentReportBonus",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/document/ReportDocument.vue"),
      },
      {
        path: "document/report/delete",
        name: "documentDeleteReportBonus",
        meta: {
          title: "Reporte de documentos de bono eliminados",
          descriptionLayout: "Reporte de documentos de bono eliminados",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Reporte de nómina",
              disabled: false,
              href: "marginCommisions",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Reporte de bonos eliminados",
              disabled: true,
              href: "documentDeleteReportBonus",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/document/ReportDeleteDocument.vue"),
      },
      {
        path: "document/legal/add",
        name: "documentLegal",
        meta: {
          title: "Documentos corporativos",
          descriptionLayout: "Documentos corporativos",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Reporte de nómina",
              disabled: false,
              href: "marginCommisions",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Docuentos",
              disabled: true,
              href: "documentLegal",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/document/DocumentLegal.vue"),
      },
      {
        path: "document/legal/report",
        name: "documentReportLegal",
        meta: {
          title: "Reporte de documentos",
          descriptionLayout: "Reporte de documentos",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Reporte de nómina",
              disabled: false,
              href: "marginCommisions",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Reporte documentos",
              disabled: true,
              href: "documentReportLegal",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/document/ReportDocumentLegal.vue"),
      },
      {
        path: "document/legal/delete",
        name: "documentDeleteReportLegal",
        meta: {
          title: "Reporte de documentos eliminados",
          descriptionLayout: "Reporte de documentos eliminados",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Reporte de nómina",
              disabled: false,
              href: "marginCommisions",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Reporte documentos eliminados",
              disabled: true,
              href: "documentDeleteReportLegal",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/document/ReportDeleteDocumentLegal.vue"),
      },
      {
        path: "document/ingresos",
        name: "documentLogin",
        meta: {
          title: "Reporte de Ingresos OKAN",
          descriptionLayout: "Reporte de Ingresos",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Reporte de nómina",
              disabled: true,
              href: "marginCommisions",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Reporte Login",
              disabled: true,
              href: "documentLogin",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/document/ReportLogin.vue"),
      },
      // {
      //   path: "ocupation",
      //   name: "ocupation",
      //   meta: {
      //     title: "Ocupación",
      //     descriptionLayout: "Ocupación detalles",
      //   },
      //   component: () => import("@/views/ocupation/IndexOcupation.vue"),
      // },
      {
        path: "consolidate",
        name: "consolidate",
        meta: {
          title: "Informe Consolidado",
          descriptionLayout: "Informe Consolidado",
          // en espera breadcrums
        },
        component: () => import("@/views/consolidate/IndexConsolidate.vue"),
      },
      {
        path: "ocupation",
        name: "ocupation",
        meta: {
          title: "Ocupación",
          descriptionLayout: "Ocupación detalles",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Ocupación",
              disabled: true,
              href: "ocupation",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/ocupation/Index.vue"),
      },
      {
        path: "ocupation/create/:id",
        name: "ocupationCreate",
        meta: {
          title: "Ocupación",
          descriptionLayout: "Ocupación detalles",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Ocupación",
              disabled: false,
              href: "ocupation",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Editar ocupación",
              disabled: true,
              href: "ocupationCreate",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/ocupation/AddSite.vue"),
      },
      {
        path: "malla",
        name: "malla",
        meta: {
          title: "Malla de turno",
          descriptionLayout: "Malla de turno",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Malla",
              disabled: true,
              href: "malla",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/malla/Index.vue"),
      },
      {
        path: "service",
        name: "service",
        meta: {
          title: "Servicios",
          descriptionLayout: "Servicios",
          // breadcrums mobile
        },
        component: () => import("@/components/Service.vue"),
      },
      {
        path: "comisiones",
        name: "commissions",
        meta: {
          title: "Comisiones",
          descriptionLayout: "Comisiones",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Reclamaciones",
              disabled: true,
              href: "commissions",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/commissions/Index.vue"),
      },
      {
        path: "margen/comisiones",
        name: "marginCommisions",
        meta: {
          title: "Margen de comisiones",
          descriptionLayout: "Margen de comisiones",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Reporte de nómina",
              disabled: true,
              href: "marginCommisions",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/marginCommisions/Index.vue"),
      },
      {
        path: "margen/comisiones/upload",
        name: "uploadMargin",
        meta: {
          title: "Adjuntar margenes de comisiones",
          descriptionLayout: "Adjuntar margenes de comisiones",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Reporte de nómina",
              disabled: false,
              href: "marginCommisions",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Adjuntar archivo",
              disabled: true,
              href: "uploadMargin",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/marginCommisions/UploadFile.vue"),
      },
      {
        path: "margen/comisiones/report/sign",
        name: "reportSign",
        meta: {
          title: "Reporte de firmas",
          descriptionLayout: "Reporte de firmas",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Reporte de nómina",
              disabled: false,
              href: "marginCommisions",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Reporte por usuarios",
              disabled: true,
              href: "reportSign",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/marginCommisions/ReportSign.vue"),
      },
      {
        path: "margen/comisiones/report/percentage",
        name: "reportPercentage",
        meta: {
          title: "Reporte de firmas por porcentaje",
          descriptionLayout: "Reporte de firmas por porcentaje",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Reporte de nómina",
              disabled: false,
              href: "marginCommisions",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Reporte por usuarios",
              disabled: false,
              href: "reportSign",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Reporte por porcentajes",
              disabled: true,
              href: "reportPercentage",
              active: "router-link-active",
            },
          ],
        },
        component: () =>
          import("@/views/marginCommisions/ReportPercentage.vue"),
      },
      {
        path: "margen/comisiones/sign",
        name: "commissionsSign",
        meta: {
          title: "Acta de comisiones",
          descriptionLayout: "Acta de comisiones",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Reporte de nómina",
              disabled: false,
              href: "marginCommisions",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Firma",
              disabled: true,
              href: "commissionsSign",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/marginCommisions/CommissionsSign.vue"),
      },
      {
        path: "margen/comisiones/provision",
        name: "provisionCommissions",
        meta: {
          title: "Provisión de comisiones",
          descriptionLayout: "Provisión de comisiones",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Reporte de nómina",
              disabled: false,
              href: "marginCommisions",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Provisiones",
              disabled: true,
              href: "provisionCommissions",
              active: "router-link-active",
            },
          ],
        },
        component: () =>
          import("@/views/marginCommisions/ProvisionCommission.vue"),
      },
      {
        path: "margen/comisiones/nueva/provision",
        name: "updateProvisionCommissions",
        meta: {
          title: "Actualizar provisión de comisiones",
          descriptionLayout: "Actualziar provisión de comisiones",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Reporte de nómina",
              disabled: false,
              href: "marginCommisions",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Adjuntar provisiones",
              disabled: true,
              href: "updateProvisionCommissions",
              active: "router-link-active",
            },
          ],
        },
        component: () =>
          import("@/views/marginCommisions/UpdateProvisionCommission.vue"),
      },
      {
        path: "notifications",
        name: "notifications",
        meta: {
          title: "Notificaciones",
          descriptionLayout: "notifications",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Notificaciones",
              disabled: true,
              href: "Notificaciones",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/notifications/Notifications.vue"),
      },
      {
        path: "atraccion/seleccion",
        name: "selection",
        meta: {
          title: "Atracción y selección",
          descriptionLayout: "Atracción y selección",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Atracción y selección",
              disabled: true,
              href: "selection",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/selection/Index.vue"),
      },
      {
        path: "atraccion/seleccion/vacantes",
        name: "selectionVacancies",
        meta: {
          title: "Crear vacantes",
          descriptionLayout: "Crear vacantes",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Atracción y selección",
              disabled: false,
              href: "selection",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Crear vacantes",
              disabled: true,
              href: "selectionVacancies",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/selection/Vacancies.vue"),
      },
      {
        path: "atraccion/seleccion/candidatos",
        name: "candidateList",
        meta: {
          title: "Listar candidatos",
          descriptionLayout: "Listar candidatos",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Atracción y selección",
              disabled: false,
              href: "selection",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Listar candidatos",
              disabled: true,
              href: "candidateList",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/selection/CandidateList.vue"),
      },
      {
        path: "atraccion/seleccion/candidatos/:id",
        name: "candidateDetails",
        meta: {
          title: "Detalle de candidatos",
          descriptionLayout: "Detalle de candidatos candidatos",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Atracción y selección",
              disabled: false,
              href: "selection",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Listar candidatos",
              disabled: false,
              href: "candidateList",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Detalle de candidatos",
              disabled: false,
              href: "candidateDetails",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/selection/CandidateDetails.vue"),
      }, 
      {
        path: "atraccion/seleccion/todasvacantes",
        name: "vacanciesList",
        meta: {
          title: "Listar vacantes",
          descriptionLayout: "Listar vacantes",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Atracción y selección",
              disabled: false,
              href: "selection",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Listar vacantes",
              disabled: true,
              href: "vacanciesList",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/selection/AllVacancies.vue"),
      },
      {
        path: "atraccion/seleccion/todasvacantes/candidatos/:id",
        name: "allCandidatesList",
        meta: {
          title: "Listar candidatos",
          descriptionLayout: "Listar candidatos",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Atracción y selección",
              disabled: false,
              href: "selection",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Listar vacantes",
              disabled: true,
              href: "vacanciesList",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Listar Candidatos",
              disabled: true,
              href: "allCandidatesList",
              active: "router-link-active",
            }
          ],
        },
        component: () => import("@/views/selection/AllCandidates.vue"),
      },
      {
        path: "nuevoDesarrollo",
        name: "newDevelopment",
        meta: {
          title: "Nuevo Desarrollo",
          descriptionLayout: "Nuevo Desarrollo",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Nuevo Desarrollo",
              disabled: true,
              href: "newDevelopment",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/newDevelopment/Index.vue"),
      },
      {
        path: "nuevoDesarrollo/own",
        name: "ownDevelopment",
        meta: {
          title: "Solicitudes propias",
          descriptionLayout: "Solicitudes propias",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Nuevo Desarrollo",
              disabled: true,
              href: "newDevelopment",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Solicitudes propias",
              disabled: true,
              href: "ownDevelopment",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/newDevelopment/IndexOwnRequests.vue"),
      },
      {
        path: "nuevoDesarrollo/own/create",
        name: "createDevelopment",
        meta: {
          title: "Crear nueva solicitud",
          descriptionLayout: "Crear nueva solicitud",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Nuevo Desarrollo",
              disabled: true,
              href: "newDevelopment",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Solicitudes propias",
              disabled: true,
              href: "ownDevelopment",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Crear nueva solicitud",
              disabled: true,
              href: "createDevelopment",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/newDevelopment/CreateProcesses.vue"),
      },
      {
        path: "nuevoDesarrollo/own/created",
        name: "createdDevelopment",
        meta: {
          title: "Solicitudes creadas",
          descriptionLayout: "Solicitudes creadas",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Nuevo Desarrollo",
              disabled: true,
              href: "newDevelopment",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Solicitudes propias",
              disabled: true,
              href: "ownDevelopment",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Solicitudes creadas ",
              disabled: true,
              href: "createdDevelopment",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/newDevelopment/ListCreatedDevelopment.vue"),
      },
      {
        path: "nuevoDesarrollo/own/progress",
        name: "progressDevelopment",
        meta: {
          title: "Solicitudes en curso",
          descriptionLayout: "Solicitudes en curso",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Nuevo Desarrollo",
              disabled: true,
              href: "newDevelopment",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Solicitudes propias",
              disabled: true,
              href: "ownDevelopment",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Solicitudes en curso ",
              disabled: true,
              href: "progressDevelopment",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/newDevelopment/ListProgressDevelopment.vue"),
      },
      {
        path: "nuevoDesarrollo/own/draft",
        name: "draftDevelopment",
        meta: {
          title: "Solicitud en borrador",
          descriptionLayout: "Solicitud en borrador",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Nuevo Desarrollo",
              disabled: true,
              href: "newDevelopment",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Solicitudes propias",
              disabled: true,
              href: "ownDevelopment",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Solicitud en Borrador ",
              disabled: true,
              href: "draftDevelopment",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/newDevelopment/ListDraftDevelopment.vue"),
      },
      {
        path: "nuevoDesarrollo/own/draftEdit",
        name: "draftEditDevelopment",
        meta: {
          title: "Editar solicitud en Borrador",
          descriptionLayout: "SEditar solicitud en Borrador",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Nuevo Desarrollo",
              disabled: true,
              href: "newDevelopment",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Solicitudes propias",
              disabled: true,
              href: "ownDevelopment",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Editar solicitud en Borrador",
              disabled: true,
              href: "draftEditDevelopment",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/newDevelopment/DraftProcesses.vue"),
      },  
      {
        path: "nuevoDesarrollo/copy",
        name: "copyDevelopment",
        meta: {
          title: "Solicitudes en copia",
          descriptionLayout: "Solicitudes en copia",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Nuevo Desarrollo",
              disabled: true,
              href: "newDevelopment",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Solicitudes en copia",
              disabled: true,
              href: "copyDevelopment",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/newDevelopment/ListCopyDevelopment.vue"),
      },
      {
        path: "nuevoDesarrollo/copy/detalle:id",
        name: "copyDetailDevelopment",
        meta: {
          title: "Detalle de solicitud",
          descriptionLayout: "Detalle de solicitud",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Nuevo Desarrollo",
              disabled: true,
              href: "newDevelopment",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Solicitudes en copia",
              disabled: true,
              href: "copyDevelopment",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Detalle de solicitud",
              disabled: true,
              href: "copyDetailDevelopment",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/newDevelopment/DetailCopyProcesses.vue"),
      },
      {
        path: "nuevoDesarrollo/own/detalle:id",
        name: "ownDetailDevelopment",
        meta: {
          title: "Detalle de solicitud",
          descriptionLayout: "Detalle de solicitud",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Nuevo Desarrollo",
              disabled: true,
              href: "newDevelopment",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Solicitudes propias",
              disabled: true,
              href: "ownDevelopment",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Detalle de solicitud",
              disabled: true,
              href: "ownDetailDevelopment",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/newDevelopment/DetailOwnProcesses.vue"),
      },
      {
        path: "nuevoDesarrollo/managment",
        name: "managementDevelopment",
        meta: {
          title: "Solicitudes",
          descriptionLayout: "Solicitudes",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Nuevo Desarrollo",
              disabled: true,
              href: "newDevelopment",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Solicitudes PM",
              disabled: true,
              href: "managementDevelopment",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/newDevelopment/IndexManagmentRequests.vue"),
      },
      {
        path: "nuevoDesarrollo/managment/asignado",
        name: "managerDevelopmentAssigned",
        meta: {
          title: "Solicitudes Asignadas",
          descriptionLayout: "Solicitudes Asignadas",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Nuevo Desarrollo",
              disabled: true,
              href: "newDevelopment",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Solicitudes PM",
              disabled: true,
              href: "managementDevelopment",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Solicitudes Asignadas",
              disabled: true,
              href: "managerDevelopmentAssigned",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/newDevelopment/ListManagmentDevelopment.vue"),
      },
      {
        path: "nuevoDesarrollo/managment/curso",
        name: "managerProgressDevelopment",
        meta: {
          title: "Solicitudes en curso",
          descriptionLayout: "Solicitudes en curso",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Nuevo Desarrollo",
              disabled: true,
              href: "newDevelopment",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Solicitudes PM",
              disabled: true,
              href: "managementDevelopment",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Solicitudes en curso",
              disabled: true,
              href: "managerProgressDevelopment",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/newDevelopment/ListManagmentDevelopmentProgress.vue"),
      },
      {
        path: "nuevoDesarrollo/managment/asignado/detalle:id",
        name: "managmentProgressDetailDevelopment",
        meta: {
          title: "Detalle de solicitud",
          descriptionLayout: "Detalle de solicitud",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Nuevo Desarrollo",
              disabled: true,
              href: "newDevelopment",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Solicitudes PM",
              disabled: true,
              href: "managementDevelopment",
              active: "router-link-active",
            },
            ,
            {
              icon: false,
              text: "Solicitudes Asignadas",
              disabled: true,
              href: "managerProgressDevelopment",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Detalle de solicitud",
              disabled: true,
              href: "managmentProgressDetailDevelopment",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/newDevelopment/DetailManagmentInProcesses.vue"),
      },
      {
        path: "nuevoDesarrollo/managment/detalle:id",
        name: "managmentDetailDevelopment",
        meta: {
          title: "Detalle de solicitud",
          descriptionLayout: "Detalle de solicitud",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Nuevo Desarrollo",
              disabled: true,
              href: "newDevelopment",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Solicitudes Asignadas",
              disabled: true,
              href: "managerDevelopmentAssigned",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Detalle de solicitud",
              disabled: true,
              href: "managmentDetailDevelopment",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/newDevelopment/DetailManagmentProcesses.vue"),
      },
      {
        path: "nuevoDesarrollo/progress:id",
        name: "detailDevelopment",
        meta: {
          title: "Detalle solicitud",
          descriptionLayout: "Detalle solicitud",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Nuevo Desarrollo",
              disabled: true,
              href: "newDevelopment",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Detalle solicitud",
              disabled: true,
              href: "progressDevelopment",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/newDevelopment/DetailGeneralProcesses.vue"),
      },
      {
        path: "disciplinarios",
        name: "processes",
        meta: {
          title: "Procesos disciplinarios",
          descriptionLayout: "Procesos disciplinarios",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Procesos disciplinarios",
              disabled: true,
              href: "processes",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/disciplinaryProcesses/Index.vue"),
      },
      {
        path: "disciplinarios/crear",
        name: "createDisciplinaryProcesses",
        meta: {
          title: "crear un nuevo proceso disciplinarios",
          descriptionLayout: "crear un nuevo proceso disciplinarios",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Procesos disciplinarios",
              disabled: true,
              href: "processes",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Crear proceso disciplinario",
              disabled: true,
              href: "createDisciplinaryProcesses",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/disciplinaryProcesses/CreateDisciplinaryProcesses.vue"),
      },
      {
        path: "disciplinarios/list",
        name: "listDisciplinaryProcesses",
        meta: {
          title: "Lista de proceso disciplinarios",
          descriptionLayout: "Lista de proceso disciplinarios",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Procesos disciplinarios",
              disabled: true,
              href: "processes",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Crear proceso disciplinario",
              disabled: true,
              href: "listDisciplinaryProcesses",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/disciplinaryProcesses/ListDisciplinaryProcesses.vue"),
      },
      {
        path: "disciplinarios/list/detalle/:id",
        name: "disciplinaryDetails",
        meta: {
          title: "Detalle del proceso disciplinario",
          descriptionLayout: "Detalle del proceso disciplinario",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Procesos disciplinarios",
              disabled: true,
              href: "processes",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Crear proceso disciplinario",
              disabled: true,
              href: "listDisciplinaryProcesses",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Detalle proceso disciplinario",
              disabled: true,
              href: "disciplinaryDetails",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/disciplinaryProcesses/DisciplinaryDetails.vue"),
      },
      {
        path: "carnet",
        name: "carnet",
        meta: {
          title: "Carnet",
          descriptionLayout: "Carnet",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Carnet",
              disabled: true,
              href: "carnet",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/carnet/Index.vue"),
      },
      {
        path: "carnet/Security",
        name: "carnetSecurity",
        meta: {
          title: "Carnet seguridad",
          descriptionLayout: "Carnet seguridad",
          breadcrums: [
            {
              icon: true,
              text: "mdi-home-outline",
              disabled: false,
              href: "home",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Carnet",
              disabled: true,
              href: "carnet",
              active: "router-link-active",
            },
            {
              icon: false,
              text: "Carnet seguridad",
              disabled: true,
              href: "carnetSecurity",
              active: "router-link-active",
            },
          ],
        },
        component: () => import("@/views/carnet/CarnetSecurity.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
