import axios from "axios";
import Api from "@/utils/api";
import moment from "moment";

const ID_TOKEN_KEY = "token";

export function isLoggedIn() {
  const idToken = getIdToken();
  setAxiosHeader();
  return !!idToken;
}

export function getIdToken() {
  if (process.env.NODE_ENV === "test") {
    return process.env.TEST_TOKEN;
  } else {
    return localStorage.getItem(ID_TOKEN_KEY);
  }
}

export function noAuth(to, from, next) {
  if (isLoggedIn()) {
    next({
      path: "/",
    });
  } else {
    next();
  }
}

export function logout() {
  clearIdToken();
  //localStorage.removeItem('rol');
  //localStorage.removeItem('userId');
}

export function login(idToken) {
  localStorage.setItem(ID_TOKEN_KEY, idToken);
  setAxiosHeader();
}

function clearIdToken() {
  localStorage.removeItem(ID_TOKEN_KEY);
}

export function setAxiosHeader() {
  axios.defaults.headers.common["Authorization"] = "Bearer " + getIdToken();
  axios.defaults.headers.common["Access-Control-Allow-Headers"] =
    "x-requested-with";
  axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
}

export function loginGoogle(idToken) {
  localStorage.setItem(ID_TOKEN_KEY, idToken);
  setAxiosHeader();
}

export async function validateSession() {
  let token = await localStorage.getItem("tokenB64");
  if (token) {
    axios
      .get(`${process.env.VUE_APP_BK_LOGIN}/api/auth/currentUser`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then(() => {

       })
      .catch((error) => {
        localStorage.clear();
        sessionStorage.clear();
        location.reload();
        // window.location.href = "https://login.okan.tools/login";
        saveLog(error.response.data.data, 357 ,'Logout por expiración Okan')
      });
  }
}

export function saveLog(cc, appId, appName) {
  let dispositivo;
  if (screen.width < 1024) {
    dispositivo = "Mobile";
  } else {
    dispositivo = "Desktop";
  }
  const record = {
    userid: cc,
    appid: appId+'',
    appname: appName,
    date: moment().format("YYYY-MM-DD"),
    dispositivo,
  };

  Api.noAuth()
    .saveAppActivity(record)
    .then((resp) => resp.json())
    .then((data) => { })
    .catch((error) => {
      console.log(error);
    });
}
