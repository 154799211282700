import { ajax } from "jquery";
import { setAxiosHeader } from "./auth";
import axios from "axios";

// var url = "https://adm.okan.tools/wp-json/okanapiwp";
// var urlOkan = "https://login.okan.tools/api"

var url = process.env.VUE_APP_BK_OKAN;
var urlOkan = process.env.VUE_APP_BK_LOGIN;
var urlOkan2 = process.env.VUE_APP_BK_LOGIN2;
var urlSelection = process.env.VUE_APP_BK_SELECTION;
var backToken = process.env.VUE_APP_BK_TOKEN;
var backTokenSelection = process.env.VUE_APP_BK_SELECTION_TOKEN;
var tokenIa = process.env.VUE_APP_TOKEN_IA;
var urlPbot = process.env.VUE_APP_GIO_PBOT;
var urlPbotOkan = process.env.VUE_APP_GIO;

setAxiosHeader();

function noAuth() {
  return {
    login: async (token) => {
      var data = {
        token: token,
      };
      return await axios.post(`${urlOkan}/api/auth/validate`, data);
    },
    getView2: async (cc, loginId ,data) => {            
      return await fetch(`${url}wp-json/okanapiwp/v1/noticias/${cc}/${loginId}`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      });
        
    },
    getViewtop5: async (cc, loginId,data) => {
      return await fetch(`${url}wp-json/okanapiwp/v1/noticias/destacadas/${cc}/${loginId}` ,{
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      });
    },
    showView: async (id) => {
      return await fetch(`${url}wp-json/okanapiwp/v1/noticias/${id}`);
    },
    showViewFeatured: async (categoryId) => {
      return await fetch(
        `${url}wp-json/okanapiwp/v1/noticias/category/${categoryId}`
      );
    },
    profile: async (documentNumber) => {
      return await axios.get(`${urlOkan}/api/auth/user/${documentNumber}`);
    },
    getBirth: async () => {
      return await axios.get(`${urlOkan}/api/user/happy/birthday`);
    },
    getBirth: async (data) => {
      return await axios.post(`${urlOkan}/api/user/happy/birthday`, data);
    },
    showOtherViews: async (postId) => {
      return await fetch(
        `${url}wp-json/okanapiwp/v1/noticias/tag/related/${postId}`
      );
    },
    showVideos: async (cc, loginId,data) => {
      return await fetch(`${url}wp-json/okanapiwp/v1/videos/${cc}/${loginId}` ,{
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      });
    },
    showAudios: async (cc, loginId,data) => {
      return await fetch(`${url}wp-json/okanapiwp/v1/podcast/${cc}/${loginId}`,{
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      });
    },
    showVideosId: async (id) => {
      return await fetch(`${url}wp-json/okanapiwp/v1/videos/${id}`);
    },
    getApps: async (cc, loginId) => {
      return await fetch(
        `${url}wp-json/okanapiwp/v1/aplicativos/${cc}/${loginId}`
        // `${url}wp-json/okanapiwp/v1/aplicativos/${cc}/10327`
        // `${url}wp-json/okanapiwp/v1/aplicativos/CALB00012HDFRMRA4/9128`
        // `${url}wp-json/okanapiwp/v1/aplicativos/1032468791/9844`
      );
    },
    userSearchApps: async (cc) => {
      return await fetch(
        `${url}wp-json/okanapiwp/v1/aplicativos/by/cedula/${cc}`
      );
    },
    appsSaveWithExpiration: async (data) => {
      return await fetch(`${url}wp-json/okanapiwp/v1/update/cedulas/on/aplicativos`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      });
    },
    profileSaveWithApp: async (data) => {
      return await fetch(`${url}wp-json/okanapiwp/v1/update/perfiles/on/aplicativos`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      });
    },
    appsAll: async () => {
      return await fetch(
        `${url}wp-json/okanapiwp/v1/aplicativos`
      );
    },
    profileAll: async () => {
      return await fetch(
        `${url}wp-json/okanapiwp/v1/perfiles`
      );
    },
    addFavoritesApp: async (data) => {
      return await fetch(`${url}wp-json/okanapiwp/v1/update-cedulas-favoritos`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      });
    },
    getControl: async () => {
      return await fetch(`${url}wp-json/wp/v2/control`);
    },
    getStatusControl: async (cc) => {
      return await fetch(`${url}wp-json/okanapiwp/v1/registro/${cc}`);
    },
    getForms: async () => {
      return await fetch(`${url}wp-json/okanapiwp/v1/encuestas/by/profile/`);
    },
    getFormsQuestions: async (data) => {
      return await fetch(`${url}wp-json/okanapiwp/v1/encuestas/${data}`);
    },
    sendForms: async (data, type) => {
      if (type == "file") {
        return await fetch(`${url}wp-json/okanapiwp/v1/encuestas/insert`, {
          method: "POST",
          body:data,
        });
      }
      return await fetch(`${url}wp-json/okanapiwp/v1/encuestas/insert`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      });
    },
    getFormsNoResponse: async (documentNumber, idLogin) => {
      return await fetch(
        `${url}wp-json/okanapiwp/v1/encuestas/not/answers/${documentNumber}/${idLogin}`
        // `${url}wp-json/okanapiwp/v1/encuestas/not/answers/1007782988/8475`
      );
    },
    getFormsAnswer: async (documentNumber) => {
      return await fetch(
        `${url}wp-json/okanapiwp/v1/encuestas/answers/${documentNumber}`
        // `${url}wp-json/okanapiwp/v1/encuestas/answers/1032468791`
      );
    },
    getFormsAnswerId: async (documentNumber, id) => {
      return await fetch(
        `${url}wp-json/okanapiwp/v1/encuestas/answers/${documentNumber}/${id}`
        // `${url}wp-json/okanapiwp/v1/encuestas/answers/1007782988/8475`
      );
    },
    getFormsNoAuth: async (data) => {
      return await fetch(`${url}wp-json/okanapiwp/v1/encuestas/for_all`);
    },
    saveAppActivity: async (data) => {
      return await fetch(`${url}wp-json/jet-cct/apps_mas_usadas`, {
        method: "POST",
        headers: {
          Authorization: `Basic ${backToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
    },
    getAppActivity: async (cc) => {
      let config = {
        headers: {
          Authorization: "Basic " + backToken,
        },
      };
      return await fetch(
        `${url}wp-json/okanapiwp/v1/apps_mas_usadas/${cc}`,
        config
      );
    },
    getDataOcupation: async () => {
      let config = {
        headers: {
          Authorization: "Basic " + backToken,
        },
      };

      return await fetch(`${url}wp-json/jet-cct/historial_tracking`, config);
    },
    getTecInfo: async (data) => {
      let config = {
        headers: {
          Authorization: "Basic " + backToken,
        },
      };

      return await fetch(
        `${url}wp-json/okanapiwp/v1/colaborador/${data}`,
        config
      );
    },
    getTutorials: async () => {
      return await fetch(`${url}wp-json/okanapiwp/v1/tutoriales/`);
    },
    getDetailsApp: async (id) => {
      return await fetch(`${url}wp-json/okanapiwp/v1/aplicativos/${id}`);
    },
    save: async (data) => {
      //envío registro control de vida
      return await fetch(`${url}wp-json/okanapiwp/v1/registro/`, {
        method: "POST",
        headers: {
          Authorization: `Basic ${backToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify([data]),
      });

      // const token = `userAPI:gce9 ZTkS yGAZ Rbhs QbB3 rUXf`;
      // const encodedToken = Buffer.from(token).toString('base64');

      // let config = {
      //   headers: {
      //     Authorization: "Basic " + encodedToken,
      //   },
      // }
      // console.log(`datos a enviar -> ${JSON.stringify(data)}`);
      // // return await axios.post(`${urlBrm}v1/registro`, data, config);
      // return await axios.post(`${url}wp-json/jet-cct/registro`, data, config);
    },
    getCantNotify: async (data) => {
      return await fetch(`${urlOkan}/api/auth/getCantHc/${data}`);
    },
    getListNotify: async (data) => {
      return await fetch(`${urlOkan}/api/auth/getHc/${data}`);
    },
    delListNotify: async (data) => {
      return await fetch(`${urlOkan}/api/auth/delHc/${data}`);
    },
    saveSafePassage: async (data) => {
      return await axios.post(`${urlOkan}/api/user/safe/passage`, data);
    },
    getDocumentReference: async (data, token) => {
      return await axios.post(
        `${urlOkan}/api/user/getDocumentReference`,
        data,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    createReport: async (data, token) => {
      return await fetch(`${urlPbot}gestion-solcitudes/solicitudes`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
    },
    getCommissions: async (data, token) => {
      return await axios.post(`${urlOkan}/api/commisicons/collaborator`, data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
    },
    getPerforming: async (token) => {
      return await axios.get(`${urlOkan}/api/performing/collaborator`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
    },
    updateProfile: async (data, token) => {
      return await axios.post(`${urlOkan}/api/user/profile/update`, data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
    },
    getReportPayroll: async (token) => {
      return await axios.get(`${urlOkan}/api/user/report/payroll`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
    },
    getDocumentById: async (data, token) => {
      return await axios.post(
        `${urlOkan}/api/user/document/sign/getDocumentsById`,
        data,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    importUserBonus: async (data, token) => {
      return await axios.post(
        `${urlOkan}/api/user/document/sign/importUserBonus`,
        data,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    managerDocumentsDownload : async (data,token) => {
      return await axios.post(
        `${urlOkan}/api/user/managerDocumentsDownload`,
        data,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    updateMultipleUsers: async (token, data) => {
      return await axios.post(
        `${urlOkan}/api/user/importUserData`,
        data,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    logoutUser: async (data) => {
      return await fetch (`https://n8nct.okan.tools/webhook/5c8a2872-2a90-4e74-aaa5-2d8273e2019b`,
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Authorization : 'Bearer 47%61#$Dd555',
          'Content-Type' : 'application/json',
        }
      });
    },
  };
}

function Auth(url = "api") {
  return {
    ldap: async (data) => {
      return await axios.post(`${url}/ldap`, data);
    },
    google: async (data) => {
      return await axios.post(`${url}/auth/google`, data);
    },
    getImg: async (cc) => {
      return await axios.get(`${urlOkan}/api/user/img/${cc}`);
    },
    getEpsPension: async () => {
      return await axios.get(`${urlOkan}/api/auth/user/eps/pension`);
    },
    getFile: async () => {
      return await axios.get(`${urlOkan}/api/files/list`);
    },
    saveFile: async (data) => {
      return await axios.post(`${urlOkan}/api/files/upload`, data);
    },
    saveInteraction: async (data) => {
      return await axios.post(`${urlOkan}/api/files/interaction`, data);
    },
    getInteraction: async (id) => {
      return await axios.get(`${urlOkan}/api/files/${id}/data`);
    },
    hideTour: async (cc, type) => {
      return await axios.get(`${urlOkan}/api/user/tour/${cc}/type/${type}`);
    },
    getSiteMap: async (floorId) => {
      return await axios.get(`${urlOkan}/api/workplace/floor/${floorId}`);
    },
    addSiteMap: async (data) => {
      return await axios.post(`${urlOkan}/api/user/map/create/`, data);
    },
    getLastPosition: async (site, floor) => {
      return await axios.get(
        `${urlOkan}/api/user/map/site/${site}/floor/${floor}/last`
      );
    },
    createAndUpdateWorkplace: async (data) => {
      return await axios.post(`${urlOkan}/api/workplace`, data);
    },
    getWorkplaceByPositionNumber: async (floorId, positionNumber) => {
      return await axios.get(
        `${urlOkan}/api/workplace/filterNum/${floorId}/${positionNumber}`
      );
    },
    deleteWorkplace: async (positionNumber) => {
      return await axios.put(`${urlOkan}/api/workplace/${positionNumber}`);
    },
    getAllHeadqueaters: async () => {
      return await axios.get(`${urlOkan}/api/headquarters`);
    },
    getFloorByHeadqueater: async (headqueaterId) => {
      return await axios.get(
        `${urlOkan}/api/floor/headqueater/${headqueaterId}`
      );
    },
    getFloorById: async (floorId) => {
      return await axios.get(`${urlOkan}/api/floor/${floorId}`);
    },
    saveImg: async (data, config) => {
      return await axios.post(`${urlOkan}/api/user/profileImage`, data, config);
    },
    signCompleted: async (config) => {
      return await axios.get(
        `${urlOkan}/api/user/document/sign/completed`,
        config
      );
    },
    signPending: async (config) => {
      return await axios.get(
        `${urlOkan}/api/user/document/sign/getUserDocumentPendingToSign`,
        config
      );
    },
    saveSign: async (data, config) => {
      return await axios.post(
        `${urlOkan}/api/user/document/sign/save`,
        data,
        config
      );
    },
    notify: async (data, config) => {
      return await axios.post(`${urlOkan}/api/sendNotifications`, data, config);
    },
    updateNotification: async (data, config) => {
      return await axios.patch(`${urlOkan}/api/auth/NotiById`, data, config);
    },
    getByDocument: async (data, config) => {
      return await axios.post(
        `${urlOkan}/api/user/getByDocument`,
        data,
        config
      );
    },
    updateOkanAccess: async (data, config) => {
      return await axios.patch(
        `${urlOkan}/api/user/updateOkanAccess`,
        data,
        config
      );
    },
    currentUser: async (token) => {
      return await axios.get(`${urlOkan}/api/auth/currentUser`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
    },
    getUsers: async () => {
      return await axios.get(`${urlOkan}/api/user/all`, {
        headers: {
          Authorization: "HrZTvmBNyQaM6jPI7sHo5ywN35ht/cplIBFeE+4Ufx8=", //prod
          // Authorization: "$2a$12$o8vr4y8MBy9XSc02usaeru/EdMjFXX7zo/juhSeW.eZ.vD2gFS/ym", //dev
        },
      });
    },
    createDocumentToSign: async (token, data) => {
      return await axios.post(
        `${urlOkan}/api/user/document/sign/createDocumentToSign`,
        data,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    pendingCreateDocument: async (token, type) => {
      return await axios.get(`${urlOkan}/api/user/document/sign/lastDocumentPendingCompletion/${type}`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
    },
    changePasswordDocument: async (token, data) => {
      return await axios.post(`${urlOkan}/api/user/document/change/password`, data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
    },
    previewDocument: async (token, data) => {
      return await axios.post(
        `${urlOkan}/api/user/document/sign/previewDocument`,
        data,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    createUsersDocumentsToSign: async (token, data) => {
      return await axios.post(
        `${urlOkan}/api/user/document/sign/createUsersDocumentsToSign`,
        data,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    filterDeleteDocuments: async (token,user_id) => {
      return await axios.get(
        `${urlOkan}/api/user/document/nosign/trakingdelete/filterManagerDocuments?user_id=${user_id}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    filterDeleteDocumentsManager: async (token,data) => {
      return await axios.post(
        `${urlOkan}/api/user/document/nosign/trakingdelete/filterManagerDocuments`,
        data,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    filterManagerDocuments: async (token, data) => {
      return await axios.post(
        `${urlOkan}/api/user/document/sign/filterManagerDocuments`,
        data,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    previewDocumentsManager: async (token, data) => {
      return await axios.post(
        `${urlOkan}/api/user/getDocumentReference/sinpassword`,
        data,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    deleteDocumentsNoFirma: async (token, id,user_id) => {
      return await axios.get(
        `${urlOkan}/api/user/document/cancel/sinfirmar/${id}/${user_id}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    
    getDocumentManager: async (token, data) => {
      return await axios.post(`${urlOkan}/api/user/document/sign/getAllManagerDocuments`, data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
    },
    cancelDocumentUser: async (token, id) => {
      return await axios.get(`${urlOkan}/api/user/document/cancel/${id}`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
    },
    managerDocumentsDownload: async (token, data) => {
      return await axios.post(
        `${urlOkan}/api/user/document/sign/managerDocumentsDownload`,
        data,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    validatePass: async (token, data) => {
      return await axios.post(
        `${urlOkan}/api/user/document/sign/validatePasswordDocument`,
        data,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    updatePass: async (token, data) => {
      return await axios.post(
        `${urlOkan}/api/user/document/sign/updateManagerPassword`,
        data,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    getDocumentAudit: async (token) => {
      return await axios.get(
        `${urlOkan}/api/user/document/sign/getAllPersonalDocuments`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    spelling: async (data) => {
      // return await fetch(`https://openai.okan.tools/webhook/fe70030f-f83b-4e1b-b074-047e19f2a279`, {
      return await fetch(`https://openai.okan.tools/webhook/81ed073d-6e60-4717-8f05-5da0d7a02cf1`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Authorization: "Linguo " + tokenIa,
          "Content-Type": "application/json",
        },
      });
    },
    spellingBrands: async (data) => {
      return await fetch(`https://openai.okan.tools/webhook/fe70030f-f83b-b3t0-b074-047e19f2a279`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Authorization: "Linguo " + tokenIa,
          "Content-Type": "application/json",
        },
      });
    },
    spellingDev: async (data) => {
      return await fetch(`https://openai.okan.tools/webhook/fe70030f-f83b-4e1b-b076-047e19f2a279`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Authorization: "Linguo " + tokenIa,
          "Content-Type": "application/json",
        },
      });
    },
    spellingBrands: async (data) => {
      return await fetch(`https://openai.okan.tools/webhook/4467ae73-73ea-4fa2-96f4-20a7ab4d2bd4`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Authorization: "Linguo " + tokenIa,
          "Content-Type": "application/json",
        },
      });
    },
    saveSpelling: async (token, data) => {
      return await axios.post(
        `${urlOkan}/api/artificialIntelligence/linguo/saveWritingTexts`, data,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    likeOrDislike: async (token, data) => {
      return await axios.post(
        `${urlOkan}/api/artificialIntelligence/linguo/likeOrDislike`, data,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    listCostCenter : async (token) => {
      return await axios.get(
        `${urlOkan2}/api/user/cc/all`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      
    },
    listSubCostCenter : async (token,data) => {
      return await axios.post(
        `${urlOkan2}/api/user/scc`, data,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      
    },
    listCampaign : async (token,data) => {
      return await axios.post(
        `${urlOkan2}/api/user/scc/campain`, data,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    downloadExcelLogsLogin: async (token,data) => {
      return await axios.post(
        `${urlOkan2}/api/user/logslogin/download`, data,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    }
  };
}

function LaborRelations(url = "api") {
  return {
    getAbsence: async (token) => {
      return await axios.get(`${urlOkan}/api/user/absences/report`);
    },
    addFileRelationLabor: async (data) => {
      return await axios.post(`${urlOkan}/api/payroll/create`, data);
    },
    getAllFile: async (token) => {
      return await axios.get(`${urlOkan}/api/payroll/getAlls`);
    },
    getDataExcel: async (id) => {
      return await axios.get(`${urlOkan}/api/payroll/getExcelByNumber/${id}`);
    },
    downloadExcel: async (id) => {
      return await axios.get(`${urlOkan}/api/payroll/download/${id}`, {
        responseType: "arraybuffer",
      });
    },
    getReference: async (token, data) => {
      return await axios.post(`${urlOkan}/api/references/getAllReferences`, data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
    },
    addReferenceOkan: async (token, data) => {
      return await axios.post(`${urlOkan}/api/references/add/user`, data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
    },
    getReportReferenceDownload: async (data, token) => {
      return await axios.post(
        `${urlOkan}/api/references/getAllReferencesDownload`,
        data,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    },
  };
}

function MarginCommisions(url = "api") {
  return {
    getClients: async (token) => {
      return await axios.get(`${urlOkan}/api/costMargin/getAllClients`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
    },
    getCampaigns: async (token) => {
      return await axios.get(`${urlOkan}/api/costMargin/getAllCampaigns`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
    },
    getContractor: async (token) => {
      return await axios.get(`${urlOkan}/api/costMargin/getAllContractors`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
    },
    getProfile: async (token) => {
      return await axios.get(`${urlOkan}/api/costMargin/getAllProfiles`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
    },
    getCostCenter: async (token) => {
      return await axios.get(`${urlOkan}/api/costMargin/getAllCostCenter`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
    },
    uploadFile: async (data, token) => {
      return await axios.post(
        `${urlOkan}/api/costMargin/uploadCommissionConditionsFile`,
        data,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    uploadFileProvisional1: async (data, token) => {
      return await axios.post(
        `${urlOkan}/api/costMargin/UploadCommissionProvision`,
        data,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    getReportSign: async (data, token) => {
      return await axios.post(
        `${urlOkan}/api/costMargin/getAllCommissionPeople`,
        data,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    getReportPercentage: async (data, token) => {
      return await axios.post(
        `${urlOkan}/api/costMargin/getAllCommissionConditions`,
        data,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    getReportSignDownload: async (data, token) => {
      return await axios.post(
        `${urlOkan}/api/costMargin/getAllCommissionPeople/download`,
        data,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    getReportPercentageDownload: async (data, token) => {
      return await axios.post(
        `${urlOkan}/api/costMargin/getAllCommissionConditions/download`,
        data,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    getSign: async (token) => {
      return await axios.get(`${urlOkan}/api/costMargin/getCustomSignature`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
    },
    getProvisional: async (data, token) => {
      return await axios.post(
        `${urlOkan}/api/costMargin/getCommissionProvision`,
        data,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    getDocumentSign: async (token) => {
      return await axios.get(`${urlOkan}/api/costMargin/getCommission`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
    },
    sign: async (data, token) => {
      return await axios.post(
        `${urlOkan}/api/costMargin/signedCommissionConditions`,
        data,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    saveSign: async (data, token) => {
      return await axios.post(
        `${urlOkan}/api/costMargin/createUserSignature`,
        data,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    dinamicFilter: async (data, token) => {
      return await axios.post(`${urlOkan}/api/costMargin/dinamicFilter`, data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
    },
  };
}

function RetirementVoluntary() {
  return {
    uploadResignationDocument: async (token, data) => {
      return await axios.post(`${urlOkan}/api/resigns/uploadResignationDocument`, data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
    },
    uploadResignationHtml: async (token, data) => {
      return await axios.post(`${urlOkan}/api/resigns/createDocument`, data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
    },
    getDocument: async (token, data) => {
      return await axios.post(`${urlOkan}/api/resigns/getResignDocument`, data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
    },
    saveForms: async (data) => {
      return await fetch(`${url}wp-json/okanapiwp/v1/update-cedulas`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      });
    },
    updateForms: async (token, data) => {
      return await axios.post(`${urlOkan}/api/resigns/updateResignStatus`, data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
    },
    getResign: async (token, data) => {
      return await axios.post(`${urlOkan}/api/resigns/getResigns`, data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
    },
    getBoss: async (token) => {
      return await axios.get(`${urlOkan}/api/user/findYourBoss`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
    },
  }
}

function Selection() {
  return {
    getVacanciesget: async () => {
      let config = {
        headers: {
          Authorization: backTokenSelection,
        },
      };
      return await fetch(
        `${urlSelection}wp-json/workapiwp/v1/vacantes`, config
      );
    },
    getVacancies: async (data) => {
      return await fetch(`${urlSelection}wp-json/workapiwp/v1/vacantes`, {
        method: "POST",
        body: data,
        headers: {
          Authorization: backTokenSelection,
        },
      });
    },
    getVacanciesFilter: async (data) => {
      return await fetch(`${urlSelection}wp-json/workapiwp/v1/filter-vacantes`, {
        method: "POST",
        body: data,
        headers: {
          Authorization: backTokenSelection,
        },
      });
    },
    getCandidates: async (data) => {
      return await fetch(`${urlSelection}wp-json/workapiwp/v1/candidatos`, {
        method: "POST",
        body: data,
        headers: {
          Authorization: backTokenSelection,
        },
      });
    },
    downloadCandidates: async (data) => {
      return await fetch(`${urlSelection}wp-json/workapiwp/v1/descargar-candidatos`, {
        method: "POST",
        body: data,
        headers: {
          Authorization: backTokenSelection,
        },
      });
    },
    createVacancies: async (data) => {
      return await fetch(`${urlSelection}wp-json/workapiwp/v1/insert_vacantes`, {
        method: "POST",
        body: data,
        headers: {
          Authorization: backTokenSelection,
        },
      });
    },
    getCountrySelection: async (token) => {
      return await axios.get(`${urlOkan}/api/ubicacion/paises`,{
        headers: {
          Authorization: "Bearer " + token,
        },
      });
    },
    getCitySelection: async (token,data) => { 
      return await axios.post(`${urlOkan}/api/ubicacion/ciudades`,data,{
        headers: {
          Authorization: "Bearer " + token,
        },
      });
    },
  }
}

function Security() {
  return {
    sendNotification: async (token, data) => {
      return await axios.post(`${urlOkan}/api/security/notification/send`, data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
    },
    getReportAbsenceDownload: async () => {
      return await axios.get(`${urlOkan}/api/user/absences/download`);
    },
  }
}

function ExtraHours() {
  return {
    getExtraHours: async (token, data) => {
      return await axios.post(`${urlOkan}/api/user/extraHours/all`, data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
    },
    approvalRejectedRequest: async (token, data) => {
      return await axios.post(`${urlOkan}/api/user/extraHours/approval/rejected`, data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
    },
    deleteRequest: async (token, data) => {
      return await axios.post(`${urlOkan}/api/user/extraHours/delete`, data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
    },
    saveExtraHours: async (token, data) => {
      return await axios.post(`${urlOkan}/api/user/extraHours/save`, data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
    },

  }
}

function DocumentTemplate() {
  return {
    getDocumentTemplate: async (token, type, area) => {
      return await axios.get(`${urlOkan}/api/user/document/template/${area}/${type}`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
    },
    saveDocumentTemplate: async (token, data) => {
      return await axios.post(`${urlOkan}/api/user/document/template/save`, data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
    },
    deleteTemplate: async (token, data) => {
      return await axios.post(`${urlOkan}/api/user/document/template/delete`,data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
    },
  }
}

function Chat() {
  return {
    sendChat: async (data) => {
      return await fetch('https://n8nct.okan.tools/webhook/757f8fdb-17db-4646-ac66-9f999912ce3a', {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Authorization: "3d524a53c110e4c22463b10ed32cef9d",
          "Content-Type": "application/json",
        },
      });
    },
  }
}

function newUser(){
  return{
    loginNewUser: async (data) => {
      return await axios.post(`${urlOkan}/api/inflow/startUsersesion`, data, {
        headers: { 
          Authorization: '$2a$12$o8vr4y8MBy9XSc02usaeru/EdMjFXX7zo/juhSeW.eZ.vD2gFS/ym',
        },
      });
    },
    logOutNewUser : async (data) => {
      return await axios.post(`${urlOkan}/api//inflow/endUsersesion`, data, {
        headers: { 
          Authorization: '$2a$12$o8vr4y8MBy9XSc02usaeru/EdMjFXX7zo/juhSeW.eZ.vD2gFS/ym',
        },
      });
    },

  }
}
function disciplinaryProcesses(){
  return{
    createProcess: async (data,token) => {
      return await axios.post(`${urlOkan}/api/disciplinary/store`, data, {
        headers: { 
          Authorization: "Bearer " + token,
        },
      });
    },
    listCostCenter : async (token,data) => {
      return await axios.post(
        `${urlOkan}/api/disciplinary/cc/all`,data,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    listSubCostCenter : async (token,data) => {
      return await axios.post(
        `${urlOkan}/api/disciplinary/scc`, data,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    listCampaign : async (token,data) => {
      return await axios.post(
        `${urlOkan}/api/disciplinary/scc/campain`, data,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    listProcess : async (token,data) => {
      return await axios.post(
        `${urlOkan}/api/disciplinary/all`, data,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    listUsers : async (token,data) => {
      return await axios.post(
        `${urlOkan}/api/disciplinary/user/all`, data,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    },
  }
}

function newDevelopment(){
  return {
    listCostCenter : async () => {
      return await axios.get(`${urlOkan}/api/user/cc/all`);
    },
    listSubCostCenter : async (token,data) => {
      return await axios.post(
        `${urlOkan}/api/user/scc`, data,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    listCampaign : async (token,data) => {
      return await axios.post(
        `${urlOkan}/api/user/scc/campain`, data,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    createDevelopment : async (token,data) => {
      return await axios.post(
        `${urlOkan}/api/workorder/store`, data,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    updateDraftDevelopment : async (token,data) => {
      return await axios.post(
        `${urlOkan}/api/workorder/draft/update`, data,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    listDevelopments : async (token,data) => {
      return await axios.post(
        `${urlOkan}/api/workorder/owner/bystatus/all`, data, 
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    listOwnerCostCenter : async (token,data) => {
      return await axios.post(
        `${urlOkan}/api/workorder/owner/cc/all`, data,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    listOwnerSubCostCenter : async (token,data) => {
      return await axios.post(
        `${urlOkan}/api/workorder/owner/scc`, data,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    listOwnerCampaign : async (token,data) => {
      return await axios.post(
        `${urlOkan}/api/workorder/owner/scc/campain`, data,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    getPriority: async () => {    
      let config = {
        headers: {
          Authorization:  backTokenSelection,
        },
      };        
      return await fetch(`${url}wp-json/okanapiwp/v1/prioridades/soporte`, config);
    },
    getRequestType: async () => {   
      let config = {
        headers: {
          Authorization:  backTokenSelection,
        },
      };            
      return await fetch(`${url}wp-json/okanapiwp/v1/tipos/solicitud/soporte`, config);
    },
    getUserSuport: async () => {  
      let config = {
        headers: {
          Authorization:  backTokenSelection,
        },
      }; 
      return await fetch(`${url}wp-json/okanapiwp/v1/usuarios/soporte`, config);
    },
    listCopyDevelopments : async (token,data) => {
      return await axios.post(
        `${urlOkan}/api/workorder/copy/bystatus/all`, data, 
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    listCopyCostCenter : async (token,data) => {
      return await axios.post(
        `${urlOkan}/api/workorder/copy/cc/all`, data,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    listCopySubCostCenter : async (token,data) => {
      return await axios.post(
        `${urlOkan}/api/workorder/copy/scc`, data,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    listCopyCampaign : async (token,data) => {
      return await axios.post(
        `${urlOkan}/api/workorder/copy/scc/campain`, data,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    createHistory : async (token,data) => {
      return await axios.post(
        `${urlOkan}/api/workorder/history/store`, data,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    getHistory : async (token,data) => {
      return await axios.post(
        `${urlOkan}/api/workorder/history/byorder`, data,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    changePriority : async (token,data) => {
      return await axios.post(
        `${urlOkan}/api/workorder/priority/update`, data,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    listSupportDevelopments : async (token,data) => {
      return await axios.post(
        `${urlOkan}/api/workorder/support/bystatus/all`, data, 
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    listSupportCostCenter : async (token,data) => {
      return await axios.post(
        `${urlOkan}/api/workorder/support/cc/all`, data,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    listSupportSubCostCenter : async (token,data) => {
      return await axios.post(
        `${urlOkan}/api/workorder/support/scc`, data,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    listSupportCampaign : async (token,data) => {
      return await axios.post(
        `${urlOkan}/api/workorder/support/scc/campain`, data,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    },
  }
}
function mallaTurno(){
  return {
    getMalla: async (id,token) => {
      return await axios.get(`${urlOkan}/api/workscheduling/by/user/all/${id}`, {
      // return await axios.get(`${urlOkan}/api/workscheduling/by/user/all/127319`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
    },
  }
}
function carnet(){
  return {
    getIDSecurity: async (token) => {
      return await axios.get(`${urlOkan}/api/security/code/entrycard/today`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
    },
    getCarnet: async (token) => {
      return await axios.get(`${urlOkan}/api/security/entrycard`, {
        responseType:"blob",
        headers: {
          Authorization: "Bearer " + token,
        },
      });
    },
  }
}
function reference(){
  return {
    listStatusReference : async (token,data) => {
      return await axios.post(
        `${urlOkan}/api/references/history/by/reference`, data,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    },
  }
}
function TYC(){
  return {
    listTyC: async (data) => {
      return await fetch(`${url}wp-json/okanapiwp/v1/tyc/v1` ,{
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Authorization:  backTokenSelection,
          "Content-Type": "application/json",
        },
      });
    },

  }
}
function DocumentsPbot() {
  return {
    getDocumentList: async (token,data) => {
      return await fetch(`${urlPbotOkan}v1/docs-corporativos/api/creados`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: data,
      });
    },
    getUsersList: async (token,data) => {
      return await fetch(`${urlPbotOkan}v1/docs-corporativos/api/asignados/firma`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: data,
      });
    },
    downloadList: async (token,data) => {
      return await fetch(`${urlPbotOkan}v1/docs-corporativos/api/asignados/firma/exportar`, {
        method: "POST",
        body: data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    },
    downloadPlantilla: async (token) => {
      return await fetch(`${urlPbotOkan}v1/docs-corporativos/api/plantillas/update/fechaEntrega`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    },
    seeDocument: async (token,idUser) => {
      return await fetch(`${urlPbotOkan}v1/docs-corporativos/api/asignados/firma/${idUser}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    },
    updateDate: async (token,data) => {
      return await fetch(`${urlPbotOkan}v1/docs-corporativos/api/asignados/firma/actualizarEntrega`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });
    },
    updateMultiple: async (token,data) => {
      return await fetch(`${urlPbotOkan}v1/docs-corporativos/api/asignados/firma/actualizarEntrega/masivo`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: data,
      });
    },
  };
}



export default {
  noAuth,
  Auth,
  LaborRelations,
  MarginCommisions,
  RetirementVoluntary,
  Selection,
  Security,
  ExtraHours,
  DocumentTemplate,
  Chat,
  newUser,
  disciplinaryProcesses,
  newDevelopment,
  mallaTurno,
  carnet,
  reference,
  TYC,
  DocumentsPbot
};
